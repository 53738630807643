import { config } from '../config/index'
import requestOptions from '@/helpers/RequestOptions'
import handleBlobResponse from '@/helpers/HandleBlobResponses'
import handleResponse from '@/helpers/HandleResponses'

export const saleService = {
  getNoOfCusByCode,
  getCustomerComm,
  getNoOfTransByCode,
  getNoOfTransByFrontline,
  getNoOfHoldShipmentByFrontline,
  getNoOfTransBySector,
  getCusByBirthMonth,
  getCustomerFull,
  downloadDiscount,
  downloadByOriginHubs,
  downloadByDestHubs,
  downloadByTransit,
  downloadByRegOriginHubs,
  downloadByRegDestHubs,
  downloadByRegTransit,
  downloadDiscByHubs,
  getDomesticReport,
  getFreightReport,
  downloadFreightReport,
  getAdminReport,
  getFsmReport,
  downloadFsmReport,
  getDomesticFrontlineReport,
  getFreightFrontlineReport,
  getFsmFrontlineReport,
  getDomesticReportByCity,
  downloadDomesticReport,
  downloadDomesticDetailReport,
  getShipment,
  getShipmentsDetails,
  getFShipment,
  getIShipment,
  generateDSS,
  getDriver,
  getDSS,
  getDSSByCity,
  getDSSDetail,
  confirmDSS,
  removefromDSS,
  retreatShipment,
  getRoutines,
  numberOfCusByCode,
  numberOfTransByCode,
  numberOfTransByFrontline,
  numberOfHoldingShipmentByFrontline,
  numberOfTransBySector,
  cusByBirthMonth,
  shipmentsAtOrigin,
  shipmentsAtDest,
  ShipmentsOnTransit,
  shipmentsAtRegOrigin,
  shipmentsAtRegDest,
  ShipmentsOnRegTransit,
  discShipmentsByHubs,
  highPointCustomers,
  totalSalesOrders,
  frontlineSummary,
  operatorSummary,
  managerSummary,
  marketerSummary,
  redeemPoint,
  getDrivers,
  getfnReport,
  updateRecipient,
  resolveShipment,
  resolveManifiest,
  resolveRSAShipment
  // createShipment,
  // createCorp,
  // createShipmentCalculate,
  // createShipmentCCalculate,
  // getCServicePrices,
  // addCServicePrice,
  // importCServicePrice,
  // updateCServicePrice
}
async function redeemPoint (data) {
  const response = await fetch(`${config.apiurl}/Customer/redeempoint`, requestOptions.post(data))
  const model = await handleResponse(response)
  return model
}
async function getNoOfCusByCode (startDate, endDate, sbuId) {
  const response = await fetch(sbuId ? `${config.apiurl}/Sale/DownloadNumberOfCustomerByCode?startDate=${startDate}&endDate=${endDate}&sbuId=${sbuId}` : `${config.apiurl}/Sale/DownloadNumberOfCustomerByCode?startDate=${startDate}&endDate=${endDate}`, requestOptions.get())
  const blob = await handleBlobResponse(response)
  return blob
}

async function getCustomerComm (id) {
  const response = await fetch(`${config.apiurl}/Sale/DownloadCustomerComm/${id}`, requestOptions.get())
  const blob = await handleBlobResponse(response)
  return blob
}

async function getNoOfTransByCode (startDate, endDate, sbuId) {
  const response = await fetch(sbuId ? `${config.apiurl}/Sale/DownloadNumberOfTransactionsByCode?startDate=${startDate}&endDate=${endDate}&sbuId=${sbuId}` : `${config.apiurl}/Sale/DownloadNumberOfTransactionsByCode?startDate=${startDate}&endDate=${endDate}`, requestOptions.get())
  const blob = await handleBlobResponse(response)
  return blob
}

async function getNoOfTransByFrontline (startDate, endDate, sbuId) {
  const response = await fetch(sbuId ? `${config.apiurl}/Sale/DownloadNumberOfTransactionsByFrontline?startDate=${startDate}&endDate=${endDate}&sbuId=${sbuId}` : `${config.apiurl}/Sale/DownloadNumberOfTransactionsByFrontline?startDate=${startDate}&endDate=${endDate}`, requestOptions.get())
  const blob = await handleBlobResponse(response)
  return blob
}

async function getNoOfHoldShipmentByFrontline (sbuId) {
  const response = await fetch(sbuId ? `${config.apiurl}/Sale/DownloadNumberOfHoldShipmentByFrontline?sbuId=${sbuId}` : `${config.apiurl}/Sale/DownloadNumberOfHoldShipmentByFrontline`, requestOptions.get())
  const blob = await handleBlobResponse(response)
  return blob
}

async function getNoOfTransBySector (startDate, endDate, sbuId) {
  const response = await fetch(sbuId ? `${config.apiurl}/Sale/DownloadNumberOfTransactionsBySector?startDate=${startDate}&endDate=${endDate}&sbuId=${sbuId}` : `${config.apiurl}/Sale/DownloadNumberOfTransactionsBySector?startDate=${startDate}&endDate=${endDate}`, requestOptions.get())
  const blob = await handleBlobResponse(response)
  return blob
}

async function getCusByBirthMonth () {
  const response = await fetch(`${config.apiurl}/Sale/DownloadCustomersByMonth`, requestOptions.get())
  const blob = await handleBlobResponse(response)
  return blob
}

async function getCustomerFull (id) {
  const response = await fetch(`${config.apiurl}/Sale/DownloadCustomerFull/${id}`, requestOptions.get())
  const blob = await handleBlobResponse(response)
  return blob
}

async function downloadDiscount () {
  const response = await fetch(`${config.apiurl}/Sale/DownloadDiscount`, requestOptions.get())
  const blob = await handleBlobResponse(response)
  return blob
}

async function downloadByOriginHubs (id, sbuid, start, end) {
  const response = await fetch(`${config.apiurl}/Sale/DownloadNumberOfShipmentAtOriginHub/${id}?sbuid=${sbuid}&startDate=${start}&endDate=${end}`, requestOptions.get())
  const blob = await handleBlobResponse(response)
  return blob
}

async function downloadByDestHubs (id, sbuid, start, end) {
  const response = await fetch(`${config.apiurl}/Sale/DownloadNumberOfShipmentAtDestinationHub/${id}?sbuid=${sbuid}&startDate=${start}&endDate=${end}`, requestOptions.get())
  const blob = await handleBlobResponse(response)
  return blob
}

async function downloadByTransit (id, sbuid, start, end) {
  const response = await fetch(`${config.apiurl}/Sale/DownloadNumberOfShipmentOnTransit/${id}?sbuid=${sbuid}&startDate=${start}&endDate=${end}`, requestOptions.get())
  const blob = await handleBlobResponse(response)
  return blob
}

async function downloadByRegOriginHubs (id, sbuid, start, end) {
  const response = await fetch(`${config.apiurl}/Sale/DownloadNumberOfShipmentAtRegionalOriginHub/${id}?sbuid=${sbuid}&startDate=${start}&endDate=${end}`, requestOptions.get())
  const blob = await handleBlobResponse(response)
  return blob
}

async function downloadByRegDestHubs (id, sbuid, start, end) {
  const response = await fetch(`${config.apiurl}/Sale/DownloadNumberOfShipmentAtRegionalDestinationHub/${id}?sbuid=${sbuid}&startDate=${start}&endDate=${end}`, requestOptions.get())
  const blob = await handleBlobResponse(response)
  return blob
}

async function downloadByRegTransit (id, sbuid, start, end) {
  const response = await fetch(`${config.apiurl}/Sale/DownloadNumberOfShipmentOnTransitByRegion/${id}?sbuid=${sbuid}&startDate=${start}&endDate=${end}`, requestOptions.get())
  const blob = await handleBlobResponse(response)
  return blob
}

async function downloadDiscByHubs (id, sbuid, type) {
  const response = await fetch(`${config.apiurl}/Sale/DownloadNumberOfDiscrepancyShipment/${id}?sbuid=${sbuid}&type=${type}`, requestOptions.get())
  const blob = await handleBlobResponse(response)
  return blob
}

async function getDomesticReport (id, startDate, endDate) {
  const response = await fetch(`${config.apiurl}/Shipment/domesticReport?sbuId=${id}&startDate=${startDate}&endDate=${endDate}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}
async function downloadDomesticReport (id, startDate, endDate) {
  const response = await fetch(`${config.apiurl}/Shipment/exportDomesticReport?sbuId=${id}&startDate=${startDate}&endDate=${endDate}`, requestOptions.get())
  const model = await handleBlobResponse(response)
  return model
}
async function downloadDomesticDetailReport (id, startDate, endDate) {
  const response = await fetch(`${config.apiurl}/Shipment/exportDomesticDetailReport?sbuId=${id}&startDate=${startDate}&endDate=${endDate}`, requestOptions.get())
  const model = await handleBlobResponse(response)
  return model
}
async function getFreightReport (startDate, endDate) {
  const response = await fetch(`${config.apiurl}/Shipment/freightReport?startDate=${startDate}&endDate=${endDate}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}
async function downloadFreightReport (startDate, endDate) {
  const response = await fetch(`${config.apiurl}/Shipment/exportfreightReport?startDate=${startDate}&endDate=${endDate}`, requestOptions.get())
  const model = await handleBlobResponse(response)
  return model
}
async function getAdminReport (id, startDate, endDate) {
  const response = await fetch(`${config.apiurl}/Shipment/adminReport?sbuId=${id}&startDate=${startDate}&endDate=${endDate}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getFsmReport (startDate, endDate) {
  const response = await fetch(`${config.apiurl}/Shipment/fsmReport?startDate=${startDate}&endDate=${endDate}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function downloadFsmReport (startDate, endDate) {
  const response = await fetch(`${config.apiurl}/Shipment/exportfsmReport?startDate=${startDate}&endDate=${endDate}`, requestOptions.get())
  const model = await handleBlobResponse(response)
  return model
}

async function getDomesticFrontlineReport (id, startDate, endDate) {
  const response = await fetch(`${config.apiurl}/Shipment/domesticReportByFrontline/${id}?startDate=${startDate}&endDate=${endDate}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getDomesticReportByCity (id, startDate, endDate) {
  const response = await fetch(`${config.apiurl}/Shipment/domesticReportByCity/${id}?startDate=${startDate}&endDate=${endDate}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}
async function getfnReport (id, startDate, endDate) {
  const response = await fetch(`${config.apiurl}/Shipment/fnReport/${id}?startDate=${startDate}&endDate=${endDate}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}
async function getFreightFrontlineReport (id, startDate, endDate) {
  const response = await fetch(`${config.apiurl}/Shipment/freightReportByFrontline/${id}?startDate=${startDate}&endDate=${endDate}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getFsmFrontlineReport (id, startDate, endDate) {
  const response = await fetch(`${config.apiurl}/Shipment/intlReportByFrontline/${id}?startDate=${startDate}&endDate=${endDate}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getShipment (id) {
  const response = await fetch(`${config.apiurl}/Shipment/detail/${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getShipmentsDetails (id) {
  const response = await fetch(`${config.apiurl}/Shipment/details/${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getFShipment (id) {
  const response = await fetch(`${config.apiurl}/Shipment/gsadetail/${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getIShipment (id) {
  const response = await fetch(`${config.apiurl}/Shipment/fsmdetail/${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function generateDSS (req) {
  const response = await fetch(`${config.apiurl}/Shipment/generateDSS`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function getDriver (id, cityId) {
  const response = await fetch(`${config.apiurl}/Misc/Drivers?sbuId=${id}&cityId=${cityId}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getDrivers (id) {
  const response = await fetch(`${config.apiurl}/Misc/Drivers/?sbuId=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getRoutines (id, cityId) {
  const response = await fetch(`${config.apiurl}/Misc/RoutinesByCity/${id}?cityId=${cityId}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getDSS (id) {
  const response = await fetch(`${config.apiurl}/Shipment/dss/${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}
async function getDSSByCity (id) {
  const response = await fetch(`${config.apiurl}/Shipment/dssbycity/${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getDSSDetail (id) {
  const response = await fetch(`${config.apiurl}/Shipment/dss/detail/${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function confirmDSS (id) {
  const response = await fetch(`${config.apiurl}/Shipment/confirmDSS?dssId=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function removefromDSS (id) {
  const response = await fetch(`${config.apiurl}/Shipment/removeDailySaleItem/${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function retreatShipment (id) {
  const response = await fetch(`${config.apiurl}/Shipment/retreat/${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}
async function resolveShipment (id) {
  const response = await fetch(`${config.apiurl}/Shipment/resolve/${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}
async function resolveRSAShipment (id) {
  const response = await fetch(`${config.apiurl}/Shipment/resolveRSA/${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}
async function resolveManifiest (id) {
  const response = await fetch(`${config.apiurl}/Shipment/resolveM/${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function numberOfCusByCode (start, end, id) {
  const response = await fetch(id ? `${config.apiurl}/Sale/NumberOfCustomerByCode?startDate=${start}&endDate=${end}&sbuId=${id}` : `${config.apiurl}/Sale/NumberOfCustomerByCode?startDate=${start}&endDate=${end}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function numberOfTransByCode (start, end, id) {
  const response = await fetch(id ? `${config.apiurl}/Sale/NumberOfTransactionsByCode?startDate=${start}&endDate=${end}&sbuId=${id}` : `${config.apiurl}/Sale/NumberOfTransactionsByCode?startDate=${start}&endDate=${end}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function numberOfTransByFrontline (start, end, id) {
  const response = await fetch(id ? `${config.apiurl}/Sale/NumberOfTransactionsByFrontline?startDate=${start}&endDate=${end}&sbuId=${id}` : `${config.apiurl}/Sale/NumberOfTransactionsByFrontline?startDate=${start}&endDate=${end}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function numberOfHoldingShipmentByFrontline (id) {
  const response = await fetch(id ? `${config.apiurl}/Sale/NumberOfHoldShipmentByFrontline?sbuId=${id}` : `${config.apiurl}/Sale/NumberOfHoldShipmentByFrontline`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function numberOfTransBySector (start, end, id) {
  const response = await fetch(id ? `${config.apiurl}/Sale/NumberOfTransactionsBySector?startDate=${start}&endDate=${end}&sbuId=${id}` : `${config.apiurl}/Sale/NumberOfTransactionsBySector?startDate=${start}&endDate=${end}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function cusByBirthMonth () {
  const response = await fetch(`${config.apiurl}/Sale/CustomersByBirthMonth`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function shipmentsAtOrigin (id, sbuid, start, end) {
  const response = await fetch(`${config.apiurl}/Sale/NumberOfShipmentAtOriginHub/${id}?sbuid=${sbuid}&startDate=${start}&endDate=${end}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function shipmentsAtDest (id, sbuid, start, end) {
  const response = await fetch(`${config.apiurl}/Sale/NumberOfShipmentAtDestinationHub/${id}?sbuid=${sbuid}&startDate=${start}&endDate=${end}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function ShipmentsOnTransit (id, sbuid, start, end) {
  const response = await fetch(`${config.apiurl}/Sale/NumberOfShipmentOnTransit/${id}?sbuid=${sbuid}&startDate=${start}&endDate=${end}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function shipmentsAtRegOrigin (id, sbuid, start, end) {
  const response = await fetch(`${config.apiurl}/Sale/NumberOfShipmentAtRegionalOriginHub/${id}?sbuid=${sbuid}&startDate=${start}&endDate=${end}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function shipmentsAtRegDest (id, sbuid, start, end) {
  const response = await fetch(`${config.apiurl}/Sale/NumberOfShipmentAtRegionalDestinationHub/${id}?sbuid=${sbuid}&startDate=${start}&endDate=${end}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function ShipmentsOnRegTransit (id, sbuid, start, end) {
  const response = await fetch(`${config.apiurl}/Sale/NumberOfShipmentOnTransitByRegion/${id}?sbuid=${sbuid}&startDate=${start}&endDate=${end}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function discShipmentsByHubs (id, sbuid, type) {
  const response = await fetch(`${config.apiurl}/Sale/NumberOfDiscrepancyShipment/${id}?sbuid=${sbuid}&type=${type}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function highPointCustomers (code) {
  const response = await fetch(`${config.apiurl}/Sale/HighPointCustomers?Code=${code}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function totalSalesOrders (sbuid, code) {
  const response = await fetch(`${config.apiurl}/Sale/SalesOrders/${sbuid}?Code=${code}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function frontlineSummary (code) {
  const response = await fetch(`${config.apiurl}/Sale/FrontlineSummary?Code=${code}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function marketerSummary (id) {
  const response = await fetch(id ? `${config.apiurl}/Sale/MarketerSummary?sbuId=${id}` : `${config.apiurl}/Sale/MarketerSummary`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function operatorSummary (sbuId, id) {
  const response = await fetch(`${config.apiurl}/Sale/OperatorSummary?sbuId=${sbuId}&id=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function managerSummary (id, sbuId, type) {
  const response = await fetch(`${config.apiurl}/Sale/ManagerSummary/${id}?sbuId=${sbuId}&Type=${type}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function updateRecipient (req) {
  const response = await fetch(`${config.apiurl}/Shipment/UpdateRecipient`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

// async function createCorp (req) {
//   const response = await fetch(`${config.apiurl}/Shipment/corp`, requestOptions.post(req))
//   const model = await handleBlobResponse(response)
//   return model
// }

// async function createShipmentCalculate (req) {
//   const response = await fetch(`${config.apiurl}/Shipment/calculate`, requestOptions.post(req))
//   const model = await handleBlobResponse(response)
//   return model
// }

// async function createShipmentCCalculate (req) {
//   const response = await fetch(`${config.apiurl}/Shipment/ccalculate`, requestOptions.post(req))
//   const model = await handleBlobResponse(response)
//   return model
// }

// async function getCServicePrices () {
//   const response = await fetch(`${config.apiurl}/Shipment/cservicePrices`, requestOptions.get())
//   const model = await handleBlobResponse(response)
//   return model
// }

// async function addCServicePrice (req) {
//   const response = await fetch(`${config.apiurl}/Shipment/addcserviceprice`, requestOptions.post(req))
//   const model = await handleBlobResponse(response)
//   return model
// }

// async function importCServicePrice (req) {
//   const response = await fetch(`${config.apiurl}/Shipment/importcserviceprice`, requestOptions.post(req))
//   const model = await handleBlobResponse(response)
//   return model
// }

// async function updateCServicePrice (req) {
//   const response = await fetch(`${config.apiurl}/Shipment/updatecserviceprice`, requestOptions.put(req))
//   const model = await handleBlobResponse(response)
//   return model
// }
